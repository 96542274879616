import React from "react";
import pauseIcon from "./pause.svg";
import playIcon from "./play.svg";

import styles from "./player.module.scss";

export const Player = ({ audioSrc }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const audioComponent = React.useRef(null);
  const icon = isPlaying ? pauseIcon : playIcon;
  const handleButtonClick = () => {
    if (isPlaying) {
      console.log(audioComponent.current);
      audioComponent.current.pause();
      setIsPlaying(false);
      return;
    }
    audioComponent.current.play();
    setIsPlaying(true);
  };

  React.useEffect(() => {
    const audioComponentRef = audioComponent.current;
    audioComponentRef.addEventListener("ended", () => setIsPlaying(false));
    return () => {
      audioComponentRef.removeEventListener("ended", () => setIsPlaying(false));
    };
  }, [isPlaying]);

  return (
    <>
      <button className={styles.button} onClick={handleButtonClick}>
        <img className={styles.icon} src={icon} alt="" />
      </button>
      <audio ref={audioComponent}>
        <source src={audioSrc} type="audio/mpeg" />
      </audio>
    </>
  );
};
