export const languages = {
  english: "english",
  russian: "russian",
};

export const translations = {
  lynx: {
    english: "Lynx",
    russian: "Рысь",
  },
  seal: {
    english: "Seal",
    russian: "Нерпа обыкновенная",
  },
  bear: {
    english: "Bear",
    russian: "Бурый медведь",
  },
  squirrel: {
    english: "Ground squirrel",
    russian: "Евражка или Берингийский суслик",
  },
  puffin: {
    english: "Puffin",
    russian: "Ипатка",
  },
  killer_whale: {
    english: "Orca",
    russian: "Косатка",
  },
  weasel: {
    english: "Weasel",
    russian: "Ласка",
  },
  eagle: {
    english: "Eagle",
    russian: "Белоплечий орлан",
  },
  volcano: {
    english: "Volcano",
    russian: "Вулкан",
  },
};
