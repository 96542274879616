import React from "react";

import { Photo } from "../photo";
import { Player } from "../player";
import { languages, translations } from "../../constants";

import styles from "./hero.module.scss";

export const Hero = ({ heroName }) => {
  const imageSrc = `${process.env.PUBLIC_URL}/heroes/${heroName}/image.png`;
  const audioEnglish = `${process.env.PUBLIC_URL}/heroes/${heroName}/audio_english.mp3`;
  const audioRussian = `${process.env.PUBLIC_URL}/heroes/${heroName}/audio_russian.mp3`;
  const translation = translations[heroName];

  if (!translation) {
    return null;
  }

  const russianFlagEmoji = () => (
    <span role="img" aria-label="russian">
      🇷🇺
    </span>
  );

  const ukFlagEmoji = () => (
    <span role="img" aria-label="english">
      🇬🇧
    </span>
  );

  const renderAudio = (audio, text, language) => {
    return (
      <div className={styles.audio}>
        <div className={styles.flagContainer}>
          {/* <span className={styles.language}> */}
          {language === languages.english && ukFlagEmoji()}
          {language === languages.russian && russianFlagEmoji()}
          {/* </span> */}
        </div>
        <Player audioSrc={audio} />
        <p className={styles.text}> {text} </p>
      </div>
    );
  };

  return (
    <>
      <Photo imageSrc={imageSrc} />
      <div className={styles.audioContainer}>
        {renderAudio(audioEnglish, translation.english, languages.english)}
        {renderAudio(audioRussian, translation.russian, languages.russian)}
      </div>
    </>
  );
};
