import React from 'react'

import styles from './photo.module.scss'

export const Photo = ({ imageSrc }) => {
  return (
    <div className={styles.container}>
      <img alt="Герои Камчатки" src={imageSrc} className={styles.photo} />
    </div>
  )
}
export default Photo